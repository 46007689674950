.SupportPage_content {
    width: calc(100% - 40px);
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SupportPage_list_card {
    width: 30%;
    height: 85vh;
}

.SupportPage_list_buttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-top: 10px;
    padding-left: 5px;
}

.SupportPage_fab_div {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.SupportPage_list {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    max-height: 68vh;
    overflow: scroll;
}

.SupportPageOpenList {
    margin-left: 20px;
    border-left: solid;
    border-width: 1px;
    border-color: lightgrey;
}