
.loginScreen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.login_subDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99vw;

}

.login_card {
    width: 40vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login_card_img_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.login_logo_img {
    width: 400px;
}

.login_card_subtitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.login_card_subtitle_text {
    font-weight: 700;
    font-size: x-large;

}



.login_card_textFields {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 190px;
}

.login_card_submit_button_div {
    display: flex;
    flex-direction: row-reverse;
}