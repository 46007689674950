.header_body {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
}

.header_left {
  width: 30%;
  padding-left: 20px;
}

.header_logo_sub_div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_logo_img {
  width: 200px;
}

.header_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}


.nav-menu {
    background-color: rgb(18, 100, 100);
    width: 240px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    transition: 850ms;
    right: -100%;
    box-shadow: 0px 5px 8px -9px silver;
    z-index: 1;
    overflow: scroll;
    
}

.nav-menu.active {
    right: 0;
    transition: 350ms;
}

.header_nav_menu_icon_button_div {
    padding-top: 5px;
    width: 100%;
    height: fit-content;
    display: flex;
}

.header_sidebar_list {
    width: 98%;
    margin-left: 1%;
}

.listItem_sidebar :hover {
    background-color: rgb(237, 155, 155);
    border-radius: 5px;
    cursor: pointer;
}

.listItem_sidebar {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 0px;
    padding: 10px;
    width: calc(100% - 10px);
    margin-left: 2px;
}

.listItem_sidebar_left {
    color: white;
   
}

.listItem_sidebar_right {
    font-size: large;
    font-weight: 600;
    margin-left: 10px;
    color: white
}