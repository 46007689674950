* {
    caret-color: rgb(18, 100, 100);
    font-family: 'Poppins', sans-serif;
  }
  
  a { color: inherit;
  text-decoration: none;
   } 
  
  * ::-webkit-scrollbar { 
    display: none;  
  }
  
  *  {
    marker: none;
  }
  
  * {
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }
  