.ArticleEdit_card {
    width: 68%;
    height: 85vh;
}

.ArticleEdit_card_content {
    width: calc(100% - 60px);
    margin-left: 20px;
    height: 77vh;
    padding: 10px;
}


.ArticleEdit_HTML_Field {
    height: 40vh;
    margin-top: 15px;
    margin-bottom: 50px;
    width: 100%;
}

.ArticleEdit_button {

}