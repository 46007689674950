.CatergoryEdit_card {
    height: 85vh;
    width: 65%;
}

.CatergoryEdit_card_textFields {
    width: calc(100% - 20px);
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
}

.CatergoryEdit_card_header_bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 10px;
    margin-top: 10px;
}
.CatergoryEdit_card_header_bar_text {
    font-weight: 600;
}

.CatergoryEdit_article_card {
    margin-left: 10px;
    width: 49%;
    height: 375px;
}

.CatergoryEdit_add_article_bar {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
}

.CatergoryEdit_add_article_list {
    width: calc(100% - 20px);
    margin-left: 10px;
    border-top: solid;
    border-color: lightgray;
    border-width: 1px;
    max-height: 300px;
    overflow: scroll;
}