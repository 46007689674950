.page_title_bar {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    margin-right: 20px;
}

.page_title { 
    font-size: large;
    font-weight: 700;
}



/* Temp Data */

.dashboard_master_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.dashboard_secondary_div {
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard_temp_card {
    padding: 50px;
    font-size: x-large;
    font-weight: 700;
}