.GroupVArticleAdd_textFields {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    height: 190px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

}

.GroupVArticleAdd_buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
}